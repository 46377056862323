import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import {Link as RouterLink} from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home'
import {Divider} from '@material-ui/core'
import SwitchListSecondary from './SwitchListSecondary'
import {azkarData} from './Data'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

function ListItemLink(props) {
  const {icon, primary, to} = props

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
        // See https://github.com/ReactTraining/react-router/issues/6056
        <RouterLink to={to} {...itemProps} innerRef={ref} />
      )),
    [to]
  )

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  )
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

const useStyles = makeStyles({
  root: {
    width: 240,
  },
})

export default function ListRouter() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Paper elevation={0}>
        <List>
          <ListItemLink to="/" primary="Home" icon={<HomeIcon />} />
          {azkarData.map((tile, index) => (
            <ListItemLink to={'/' + tile.pathname} primary={tile.title} icon={<tile.icon />} />
          ))}
          <ListItemLink
            to="/translitration-table"
            primary="Translitration Table"
            icon={<HelpOutlineIcon />}
          />
        </List>
        <Divider />
        <SwitchListSecondary />
      </Paper>
    </div>
  )
}
