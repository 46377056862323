import React from 'react'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import {indigo} from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
  indigo: {
    color: theme.palette.getContrastText(indigo[400]),
    backgroundColor: indigo[400],
  },
}))

const rows = [
  {
    symbol: 'أَ',
    description: 'A     about',
  },
  {
    symbol: 'آ',
    description: 'a cat',
  },
  {
    symbol: 'أُ',
    description: 'o on',
  },
  {
    symbol: 'ع',
    description: 'AA     say “a” twice distinctly with an open mouth',
  },
  {
    symbol: 'ب',
    description: 'b box',
  },
  {
    symbol: 'د',
    description: 'd door',
  },
  {
    symbol: 'ض',
    description: 'd heavy “d” sound ( open jaw but keep lips tightly round i.e : duh',
  },
  {
    symbol: 'ي',
    description: 'ee feet',
  },
  {
    symbol: 'ف',
    description: 'f fish',
  },
  {
    symbol: 'غ',
    description:
      'gh the sound you make when gargling (Touch very back of tongue to very back of mouth',
  },
  {
    symbol: 'هـ',
    description: 'h hat',
  },
  {
    symbol: 'ح',
    description:
      'h heavy “ h” sound (drop back of tongue to open back of throat, then force air out for”h”',
  },
  {
    symbol: 'إِ',
    description: 'I ink',
  },
  {
    symbol: 'ج',
    description: 'j jar',
  },
  {
    symbol: 'ك',
    description: 'k kit',
  },
  {
    symbol: 'خ',
    description: 'kh gravely “h” sound (touch back of tongue to roof of mouth and force air out)',
  },
  {
    symbol: 'ل',
    description: 'l look',
  },
  {
    symbol: 'م',
    description: 'm man',
  },
  {
    symbol: 'ن',
    description: 'n nurse',
  },
  {
    symbol: 'و',
    description: 'oo pool',
  },
  {
    symbol: 'ق',
    description: 'q queen ( “k” sound made in back of throat',
  },
  {
    symbol: 'ر',
    description: 'r rabbit (rolled “r” sound  similar to Spanish “r”)',
  },
  {
    symbol: 'ش',
    description: 'sh ship',
  },
  {
    symbol: 'س',
    description: 's sea',
  },
  {
    symbol: 'ص',
    description: 's heavy “s” sound ( open jaw but keep   lips tightly  round',
  },
  {
    symbol: 'ت',
    description: 't tan',
  },
  {
    symbol: 'ط',
    description: 't heavy “ t” sound ( open jaw but keep lips slightly  round )',
  },
  {
    symbol: 'ث',
    description: 'th think',
  },
  {
    symbol: 'ذ',
    description: 'th the',
  },
  {
    symbol: 'ظ',
    description: 'th “th” sound as in “the” but heavier (open jaw  but keep lips slightly round)',
  },
  {
    symbol: 'ُ',
    description: 'u put',
  },
  {
    symbol: 'و',
    description: 'w water',
  },
  {
    symbol: 'أْ + ء',
    description: '/ pronounce the letter before but cut it short by  stopping suddenly',
  },
  {
    symbol: 'يْ',
    description: 'y yarn',
  },
  {
    symbol: 'ز',
    description: 'z zebra',
  },
  {
    symbol: '(-)',
    description: 'is to make some words easier to read',
  },
]

export default function TranslitrationTable() {
  const classes = useStyles()

  return (
    <div className={classes.root} dir="ltr">
      <Grid container spacing={2}>
        {rows.map(row => (
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Grid container justify="center" spacing={2}>
                <Grid item>
                  <Avatar className={classes.indigo}>{row.symbol}</Avatar>
                </Grid>
                <Grid item xs>
                  <Typography>{row.description}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
