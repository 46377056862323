import {red} from '@material-ui/core/colors'
import {createMuiTheme} from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: ['Amiri', 'serif'],
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontWeight: 500,
      lineHeight: 2,
    },
    button: {
      fontStyle: 'italic',
      fontSize: 30,
      fontFamily: ['serif'],
    },
    h5: {
      lineHeight: 2,
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  arabicfont: {
    fontFamily: [
      'Amiri',
      'Hafs',
      'Adobe Arabic',
      'Simplified Arabic',
      'Traditional Arabic',
      'Arabic Typesetting',
      'Times New Roman',
      'Tahoma',
      'Arial',
      'serif',
    ],
  },
})

export default theme
