import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Switch from '@material-ui/core/Switch'
import {useCookies} from 'react-cookie'
import TranslateIcon from '@material-ui/icons/Translate'
import LanguageIcon from '@material-ui/icons/Language'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 240,
    backgroundColor: theme.palette.background.paper,
  },
}))

export default function SwitchListSecondary() {
  const classes = useStyles()
  const [cookies, setCookie] = useCookies([
    'englishTranslationCookie',
    'englishTransliterationCookie',
  ])

  const [state, setState] = React.useState({
    englishTranslationCookie: stringToBoolean(cookies.englishTranslationCookie || false),
    englishTransliterationCookie: stringToBoolean(cookies.englishTransliterationCookie || false),
  })

  const handleChange = name => event => {
    setState({...state, [name]: event.target.checked})
    setCookie(name, event.target.checked, {path: '/'})
  }

  function stringToBoolean(string) {
    if (typeof string === 'boolean') {
      return string
    }
    switch (string.toLowerCase()) {
      case 'false':
      case 'no':
      case '0':
      case '':
      case false:
        return false
      default:
        return true
    }
  }

  return (
    <List subheader={<ListSubheader>Settings</ListSubheader>} className={classes.root}>
      <ListItem>
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-english" primary="English" />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onChange={handleChange('englishTranslationCookie')}
            checked={state.englishTranslationCookie}
            color="primary"
            inputProps={{'aria-labelledby': 'switch-list-label-english'}}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <TranslateIcon />
        </ListItemIcon>
        <ListItemText id="switch-list-label-transliteration" primary="Transliteration" />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onChange={handleChange('englishTransliterationCookie')}
            checked={state.englishTransliterationCookie}
            color="primary"
            inputProps={{
              'aria-labelledby': 'switch-list-label-transliteration',
            }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}
