import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {Grid, Box, Card, CardContent, Divider} from '@material-ui/core'
import {withCookies} from 'react-cookie'
import {azkarData} from './Data'

const styles = theme => ({
  root: {
    margin: theme.spacing(6, 0, 3),
  },
  card: {
    minWidth: 275,
  },
})

function stringToBoolean(string) {
  switch (string.toLowerCase()) {
    case 'false':
    case 'no':
    case '0':
    case '':
    case false:
      return false
    default:
      return true
  }
}

class Home extends React.Component {
  state = {
    enableTranslation: true,
    enableTransliteration: true,
  }

  constructor(props) {
    super(props)

    const {cookies} = props
    this.state = {
      enableTranslation: stringToBoolean(cookies.get('englishTranslationCookie') || 'false'),
      enableTransliteration: stringToBoolean(
        cookies.get('englishTransliterationCookie') || 'false'
      ),
    }
  }

  render() {
    const {classes, cookies} = this.props
    const randomAzkar = azkarData[Math.floor(Math.random() * azkarData.length)]
    const randomDuaa = randomAzkar.azkar[Math.floor(Math.random() * randomAzkar.azkar.length)]

    return (
      <main>
        <div>
          <Typography className={classes.root} variant={'h3'}>
            Azkar Today
          </Typography>

          <Typography className={classes.root} variant={'h6'}>
            The Prophet ṣallallāhu 'alayhi wa sallam (peace and blessings of Allāh be upon him)
            said: “Should I not inform you of the best of your deed, and the purest of them with
            your Master, and the highest of them in your ranks, and what is better for you than
            spending gold and silver, and better for you than meeting your enemy and striking their
            necks, and they strike your necks?” They said: “Of course.” He ṣallallāhu 'alayhi wa
            sallam (peace and blessings of Allāh be upon him) said, “The remembrance of Allah
            subḥānahu wa ta'āla (glorified and exalted be He).” [Jami at-Tirmidhi])
          </Typography>
        </div>

        <Grid className={classes.root} container spacing={2} direction={'row'}>
          <Grid key="random-duaa" item xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <Box component="div" whiteSpace="normal">
                  <Typography variant={'h5'} align="center">
                    Random Duaa
                  </Typography>

                  <Typography variant="h6" color="textSecondary" align="center" gutterBottom>
                    {randomDuaa.subtitle}
                  </Typography>
                  <Typography variant="h5" align="center">
                    {randomDuaa.body.split('\n').map((item, i) => (
                      <p key={i}>{item}</p>
                    ))}
                  </Typography>
                  <Typography variant="h5" color="textSecondary" align="center">
                    {randomDuaa.source}
                  </Typography>
                  <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                    {randomDuaa.zekrbless}
                  </Typography>
                  {stringToBoolean(cookies.get('englishTranslationCookie') || 'false') &&
                  randomDuaa.translation ? (
                    <div>
                      <Divider />
                      <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                        English
                      </Typography>
                      <Typography variant="h5" align="center">
                        {randomDuaa.translation.split('\n').map((item, i) => (
                          <p key={i}>{item}</p>
                        ))}
                      </Typography>
                    </div>
                  ) : (
                    ''
                  )}
                  {stringToBoolean(cookies.get('englishTransliterationCookie') || 'false') &&
                  randomDuaa.transliteration ? (
                    <div>
                      <Divider />
                      <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                        Transliteration
                      </Typography>
                      <Typography variant="h5" align="center">
                        {randomDuaa.transliteration.split('\n').map((item, i) => (
                          <p key={i}>{item}</p>
                        ))}
                      </Typography>
                    </div>
                  ) : (
                    ''
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </main>
    )
  }
}

Home.propTypes = {
  classes: PropTypes.string.isRequired,
}

export default withCookies(withStyles(styles)(Home))
