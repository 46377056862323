import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import ListRouter from './ListRouter'
import {Route, Switch, BrowserRouter as Router} from 'react-router-dom'
import AzkarWrapper from './AzkarWrapper'
import Home from './Home'
import {azkarData} from './Data'
import TranslitrationTable from './TranslitrationTable'

const drawerWidth = 240
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
}))
function ResponsiveDrawer() {
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }
  var oldPathname = ''

  function getTitle(pathname) {
    if (oldPathname !== '' && mobileOpen) {
      handleDrawerToggle()
    }
    oldPathname = pathname

    if (pathname === '/translitration-table') {
      return 'Translitration Table'
    }

    var index = azkarData.findIndex(azkar => azkar.pathname === pathname.slice(1))
    return index > -1 ? azkarData[index].title : 'Azkar Book'
  }

  const drawer = <ListRouter />

  return (
    <div className={classes.root}>
      <Router initialEntries={['/drafts']} initialIndex={0}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Switch>
              <Route>
                {({location}) => (
                  <Typography variant="h6" noWrap>
                    {getTitle(location.pathname)}
                  </Typography>
                )}
              </Route>
            </Switch>
          </Toolbar>
        </AppBar>

        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: false, // Better open performance on mobile.
              }}
            >
              <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                <CloseIcon />
              </IconButton>
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.toolbar} />
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <div className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            {azkarData.map((tile, index) => (
              <Route
                exact
                path={'/' + tile.pathname}
                render={props => <AzkarWrapper {...props} />}
              />
            ))}
            <Route
              exact
              path="/translitration-table"
              render={props => <TranslitrationTable {...props} />}
            />
            <Route exact path="/" render={props => <Home {...props} />} />
          </Switch>
        </div>
      </Router>
    </div>
  )
}
ResponsiveDrawer.propTypes = {
  container: PropTypes.object,
}
export default ResponsiveDrawer
