import React from 'react'
import {SvgIcon} from '@material-ui/core'
import {ReactComponent as CarpetSvg} from './resources/carpet.svg'
import {ReactComponent as PraySvg} from './resources/pray.svg'
import {ReactComponent as ClothesSvg} from './resources/clothes.svg'
import {ReactComponent as SickSvg} from './resources/sick.svg'
import {ReactComponent as DealSvg} from './resources/deal.svg'
import {ReactComponent as RamadanSvg} from './resources/ramadan.svg'
import {ReactComponent as KaabaSvg} from './resources/kaaba.svg'
import {ReactComponent as InvocationSvg} from './resources/invocation.svg'
import {ReactComponent as FuneralSvg} from './resources/funeral.svg'
import {ReactComponent as QuranSvg} from './resources/quran.svg'

function Carpet() {
  return (
    <SvgIcon>
      <CarpetSvg />
    </SvgIcon>
  )
}

function Pray() {
  return (
    <SvgIcon>
      <PraySvg />
    </SvgIcon>
  )
}

function Clothes() {
  return (
    <SvgIcon>
      <ClothesSvg />
    </SvgIcon>
  )
}

function Sick() {
  return (
    <SvgIcon>
      <SickSvg />
    </SvgIcon>
  )
}

function Deal() {
  return (
    <SvgIcon>
      <DealSvg />
    </SvgIcon>
  )
}

function Ramadan() {
  return (
    <SvgIcon>
      <RamadanSvg />
    </SvgIcon>
  )
}

function Kaaba() {
  return (
    <SvgIcon>
      <KaabaSvg />
    </SvgIcon>
  )
}

function Invocation() {
  return (
    <SvgIcon>
      <InvocationSvg />
    </SvgIcon>
  )
}

function Funeral() {
  return (
    <SvgIcon>
      <FuneralSvg />
    </SvgIcon>
  )
}

function Quran() {
  return (
    <SvgIcon>
      <QuranSvg />
    </SvgIcon>
  )
}

export {Carpet, Pray, Clothes, Sick, Deal, Ramadan, Kaaba, Invocation, Funeral, Quran}
