import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {withRouter} from 'react-router-dom'
import {Grid, Box, Card, CardContent, Divider} from '@material-ui/core'
import RepeatIcon from '@material-ui/icons/Repeat'
import Fab from '@material-ui/core/Fab'
import {withCookies} from 'react-cookie'
import {azkarData} from './Data'

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 275,
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    width: '100%',
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
}))

function getData(pathname) {
  var index = azkarData.findIndex(azkar => azkar.pathname === pathname.slice(1))
  return azkarData[index > -1 ? index : 0].azkar
}

function stringToBoolean(string) {
  switch (string.toLowerCase()) {
    case 'false':
    case 'no':
    case '0':
    case '':
    case false:
      return false
    default:
      return true
  }
}

class AzkarWrapper extends React.Component {
  state = {
    enableTranslation: true,
    enableTransliteration: true,
  }

  constructor(props) {
    super(props)

    const {cookies} = props
    this.state = {
      enableTranslation: stringToBoolean(cookies.get('englishTranslationCookie') || 'false'),
      enableTransliteration: stringToBoolean(
        cookies.get('englishTransliterationCookie') || 'false'
      ),
    }
  }

  render() {
    const classes = useStyles
    const pathname = this.props.location.pathname
    const data = getData(pathname)
    const {cookies} = this.props

    return (
      <main>
        <Grid container spacing={2} dir="rtl" direction={'row'}>
          {data.map((tile, index) => (
            <Grid key={pathname + index} item xs={12}>
              <Card className={classes.card}>
                <CardContent>
                  <Box component="div" whiteSpace="normal">
                    <Typography variant="h6" color="textSecondary" align="center" gutterBottom>
                      {tile.subtitle}
                    </Typography>
                    <Typography variant="h5" align="center">
                      {tile.body.split('\n').map((item, i) => (
                        <p key={i}>{item}</p>
                      ))}
                    </Typography>
                    <Typography variant="h5" color="textSecondary" align="center">
                      {tile.source}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                      {tile.zekrbless}
                    </Typography>
                    {stringToBoolean(cookies.get('englishTranslationCookie') || 'false') &&
                    tile.translation ? (
                      <div>
                        <Divider />
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          align="center"
                          gutterBottom
                        >
                          English
                        </Typography>
                        <Typography variant="h5" align="center">
                          {tile.translation.split('\n').map((item, i) => (
                            <p key={i}>{item}</p>
                          ))}
                        </Typography>
                      </div>
                    ) : (
                      ''
                    )}
                    {stringToBoolean(cookies.get('englishTransliterationCookie') || 'false') &&
                    tile.transliteration ? (
                      <div>
                        <Divider />
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          align="center"
                          gutterBottom
                        >
                          Transliteration
                        </Typography>
                        <Typography variant="h5" align="center">
                          {tile.transliteration.split('\n').map((item, i) => (
                            <p key={i}>{item}</p>
                          ))}
                        </Typography>
                      </div>
                    ) : (
                      ''
                    )}
                    <Grid item xs={12} dir="ltr">
                      <Grid container justify="space-around" alignItems="center" spacing={2}>
                        <Grid key={'index'} item xs={6}>
                          <Typography variant="h5" color="textSecondary" align="left">
                            {index + 1}
                          </Typography>
                        </Grid>
                        <Grid key={'counter'} item>
                          <Counter count={tile.count} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </main>
    )
  }
}

class Counter extends React.Component {
  state = {
    count: this.props.count,
  }

  handleClick = () => {
    this.setState(prevState => ({
      count: prevState.count - 1 < 0 ? 0 : prevState.count - 1,
    }))
  }

  render() {
    return (
      <Fab
        variant="extended"
        size="large"
        color="primary"
        aria-label="counter"
        onClick={this.handleClick}
        dir="ltr"
        align="center"
      >
        <RepeatIcon />
        {this.state.count}
      </Fab>
    )
  }
}

export default withCookies(withRouter(AzkarWrapper))
